import React from "react";
import Navbar2 from "./components/Navbar1";
import Head from "./components/Head";

function App() {
  return (
    <div className="App">
      <Head />
      <Navbar2 />
    </div>
  );
}

export default App;
