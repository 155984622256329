        import React, { useEffect, useState } from 'react';
        import axios from 'axios';
        import CategoryCard from './CategoryCard';
        import "../style/CategoryCard.css";
import Footer from './Footer';

        const CategoryData = () => {
        const [Categories, setCategories] = useState([]);
        const [Result,setResult] = useState()

        useEffect(() => {
            const fetchData = async () => {
              try{
                const response = await fetch('https://divineyogaforwomen.frappe.cloud/api/method/lms.lms.doctype.lms_category.api.get_category_details', {
                  method: 'GET',
                  headers: {
                    'Authorization': 'Token a05d3fcba67eb13:4e965d4f9c818d3'
                  }
                });
                const result = await response.json();
                setCategories(result.message.data);
                setResult(true)
              }
              catch(error){
                setResult(false)
              }
            };
        
            fetchData();
          }, []);


        return (
          <>
          <h4 className='Category-heading-one'>CATEGORY</h4>
            <div className='CategoryData-main-div'>
              {
                Result==true ?
                Categories.map((item,index)=>(
                  <div key={index}>
                    <CategoryCard item={item} itemRoute={item.route}/>
                  </div>
                ))
                :
                <h4 className='Category-heading-one'>No Data Found</h4>
              }
               
            </div>
            <Footer />
          </>
        );
        };

        export default CategoryData;
